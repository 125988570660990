import Head from '../components/informational/Head'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const NotFoundPage = () => {
  // For some reason Gatsby builds this page for SSR, tmp fix:
  if (!process.browser) {
    return ''
  }

  return (
    <>
      <Head title='Not found' />

      <Typography variant='h1' gutterBottom>
        NOT FOUND
      </Typography>
      <Typography variant='body1' gutterBottom>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>
    </>
  )
}

export default NotFoundPage
