import { StaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'
import shareImage from '../../images/website-downloader-share-image.jpg'

const Head = ({ data, children, title, description, frontPage, landinPage }) => {
  if (title) {
    if (!frontPage && !landinPage) {
      title = `${title} | ${data.site.siteMetadata.title}`
    }
  } else {
    title = data.site.siteMetadata.title
  }

  description = description || data.site.siteMetadata.description
  return (
    <>
      <Helmet>
        <html lang={data.site.siteMetadata.lang} />
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='robots' content='noodp' />
        <meta property='og:locale' content={data.site.siteMetadata.locale} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={`${data.site.siteMetadata.siteUrl}/`} />
        <meta property='og:site_name' content={data.site.siteMetadata.siteName} />
        <meta property='og:image' content={`${data.site.siteMetadata.siteUrl}/${shareImage}`} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:site' content={data.site.siteMetadata.siteName} />
        <meta name='twitter:image' content={shareImage} />
        {frontPage &&
          <link rel='canonical' href={`${data.site.siteMetadata.siteUrl}/`} />
        }
        {children}
      </Helmet>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteName
            siteUrl
            locale
            lang
          }
        }
      }
    `}
    render={data => <Head data={data} {...props} />}
  />
)

Head.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        lang: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}
